.widget-cfour-slider-owl-pro {
  .owl-carousel {
    margin: 2rem 0;
    display: block;
    .owl-item {
      img {
        width: 100%;
        &:not([src]) {
          opacity: 0;
        }
      }
      .owl-lazy {
        opacity: 1;
      }
    }
    .item-video {
      height: 300px;
    }
    .item {
      height: 10rem;
      h4 {
        color: #FFF;
        font-weight: 400;
        margin-top: 0;
      }
    }
    &.owl-theme {
      .owl-nav {
        [class*="owl-"] {
          opacity: 1;
        }
        &.disabled-arrows {
          [class*="owl-"] {
            opacity: 0;
          }
        }
      }
    }
    .owl-dots {
      .owl-dot {
        background: transparent;
        border: none;
        span {
          margin: 5px 7px;
        }
      }
    }
  }

  .owl-item .widget-caption {
    position: absolute;
    text-align: center;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    h2, .h2 {
      padding: .5rem 1rem;
      font-size: 1.75rem;
      margin: 0;
      display: inline-block;
      transition: 300ms all ease;
      @media (min-width:768px) {
        font-size: 3rem;
      }
    }
  }
}
